.picking {
  &__header {
    position: relative;
    color: $color-primary;
    font-size: 2rem;
    padding: 2rem;
  }

  &__sync {
    display: flex;
    justify-content: flex-end;
  }

  &__list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem 0;

    .accordion {
      &__content {
        gap: 0.5rem;
      }
    }

    .accordion-title {
      display: flex;
      align-items: center;
      width: 100%;

      &__date {
        flex: 1;
        font-size: 2rem;
      }

      &__remaining-order {
        flex: 2;
        font-size: 1.6rem;

        span {
          padding: 0 1rem;
        }
      }
    }
  }

  .product-can-be-delivered-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10000;

    &__container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 2rem;
      gap: 2rem;
      border: 1px solid black;
      background-color: white;
    }

    &__text {
      font-weight: bold;
      color: black;
      font-size: 1.8rem;
    }

    &__actions {
      display: flex;
      gap: 2rem;

      button {
        border: none;
        &:first-of-type {
          background-color: $softRed;
        }

        &:last-of-type {
          background-color: $green;
        }
      }
    }
  }
}

@import "./OrderItem/OrderItem";
@import "./PickingTable/PickingTable";
@import "./PickingArticle/PickingArticle";
