$reorderOffset: -4rem;

.round-delivery-item {
  position: relative;
  padding: 1rem 1.2rem 1rem 1.1rem;
  border-top: solid 1px;
  color: $color-primary;

  &:hover,
  &:active,
  &:focus {
    color: #1c7fd1;
  }

  .smsTag {
    position: absolute;
    right: 0;
    top: 0.5rem;
    background: #ddd;
    color: white;
    font-weight: bold;
    font-size: 14px;
    border-radius: 8px;
    padding: 2px 5px;
    transition: transform 0.3s ease-out;

    .fa-check {
      padding-right: 4px;
      color: #37b737a1;
    }

    &.reorder-offset {
      transform: translateX($reorderOffset);
    }

    & + .row > .column:last-child {
      padding-right: 45px;
    }
  }

  .row {
    flex-direction: row;
  }

  .accessory {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translate(0, -50%);
    opacity: 0.5;
    transition: opacity 0.3s;

    &.hidden {
      opacity: 0;
    }
  }

  &.-delayed {
    & i,
    & div {
      opacity: 0.5;
    }

    & h4 {
      margin-top: 10px;
      margin-bottom: 0;
      opacity: 0.9;
      font-weight: bold;
    }
  }

  .reorder {
    position: absolute;
    right: -0.25rem;
    padding: 0.5rem;
    margin: 0;
    height: auto;
    line-height: initial;

    &.-up {
      top: 0.5em;
    }
    &.-down {
      bottom: -0.25em;
    }
  }

  &.-validated .accessory {
    color: #27ae60;
  }

  &.-delayed .accessory {
    color: #1c7fd1;
  }

  .validationIcon {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(-75%, -50%);
    color: #27ae60;
  }

  &.-delayed .validationIcon {
    color: #1c7fd1;
  }

  .options {
    margin-top: 1rem;
  }

  .observation {
    margin-top: 1rem;
  }

  .label {
    font-weight: bold;
  }
}

.delivery-options {
  padding: 10px 0;

  > i {
    margin-right: 10px;
  }
}

.delivery-item {
  margin-bottom: 1.5rem;

  &__picking-btn {
    span {
      margin-right: 0.5rem;
    }

    &:hover,
    &:active,
    &:focus {
      color: #1c7fd1;
    }

    &--completed {
      background-color: $green;
    }

    &--partially {
      background-color: $orange;
    }

    &--failed {
      background-color: $red;
    }
  }
}
