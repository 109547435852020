@use "sass:math";

@mixin radio($radioSize) {
  .radio {
    font-size: $extraLargeFontSize;
    line-height: 1;
    font-weight: 600;
    color: $blue-itm;
    display: flex;
    align-items: center;
    gap: 1.5rem;
    margin-bottom: 0;

    input[type="radio"] {
      display: grid;
      place-content: center;
      appearance: none;
      /* For iOS < 15 to remove gradient background */
      background-color: #fff;
      /* Not removed via appearance */
      margin: 0;
      font: inherit;
      color: currentColor;
      width: $radioSize;
      min-width: $radioSize;
      height: $radioSize;
      border: 1px solid $blue-itm;
      border-radius: 50%;
    }

    input[type="radio"]::before {
      content: "";
      width: $radioSize - 0.8rem;
      height: $radioSize - 0.8rem;
      border-radius: 50%;
      transform: scale(0);
      transition: 120ms transform ease-in-out;
      box-shadow: inset 1em 1em #ffab00;

      /* Windows High Contrast Mode */
      background-color: #ffab00;
    }

    input[type="radio"]:checked::before {
      transform: scale(1);
    }
  }

  @media screen and (min-width: $tablet) {
    .radio {
      font-size: 2.6rem;
      gap: 2rem;
    }
  }
}

@include radio(3rem);
