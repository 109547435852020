/*
//
// Global Components
//
*/

.input-hint {
  font-size: 1.4rem;
  margin: 0;
  padding: 0;
}

input + .input-hint {
  margin-top: -1rem;
}
