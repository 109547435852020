.cancel-screen {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.81);

  &__container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 1rem;
    width: 200px;
    height: 200px;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 0.25rem;
    box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.1);
  }

  &__button {
    position: absolute;
    padding: 0;
    top: 50%;
    left: 50%;
    width: 100px;
    height: 40px;
    transform: translate(-50%, -50%);
    cursor: pointer;
    background-color: #cb3837;
    color: #fff;
    font-weight: bold;
    border: none;
    border-radius: 0.25rem;
    transition: all, 0.3s;
    box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.1);

    &:hover,
    &:focus {
      background-color: #fff;
      color: #cb3837;
      border: 1px solid #cb3837;
    }
  }
}
