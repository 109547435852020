@use "sass:color";

%rowGap {
  margin-bottom: 6rem;
}

.quality-sheet {
  margin: 0 1rem;
  background-color: white;
  font-family: "Montserrat";

  button {
    font-family: "Montserrat";
  }

  .delivery-submit {
    button {
      text-transform: none;
    }
  }

  &__content {
    padding: 1.5rem;
  }

  &__top-container {
    text-align: center;
    width: 100%;
    margin-bottom: 4rem;

    .back-button {
      display: block;
      margin-bottom: 0;
      padding: 1.25rem 2rem;
      height: auto;
      font-size: $largeFontSize;
      text-transform: none;
      letter-spacing: 0;
      margin-bottom: 2rem;

      i {
        margin-right: 0.5rem;
      }
    }

    img {
      width: 22rem;
      height: 5.8rem;
    }
  }

  &__title {
    text-align: center;
    font-size: $extraLargeFontSize;
    line-height: 4.2rem;
    font-weight: bold;
    text-transform: uppercase;
    color: $blue-itm;
    letter-spacing: 0;
    border-top: 2px dashed $blue-itm;
    border-bottom: 2px dashed $blue-itm;
    padding: 2rem 0;
    margin-bottom: 3.5rem;
    letter-spacing: 0;
  }

  &__guidance {
    text-align: center;
    font-size: $largeFontSize;
    line-height: 2.7rem;
    letter-spacing: 0;
    color: #230b43;
    margin-bottom: 5.7rem;
  }

  &__confirmation {
    margin-bottom: 8rem;

    .checkbox {
      &__label {
        font-size: $veryLargeFontSize;
        line-height: 3.2rem;
        font-weight: 500;
      }
    }
  }

  .quality-sheet-form {
    &__error {
      margin-bottom: 2.1rem;
      color: #e64b4b;
      font-size: $largeFontSize;
      line-height: 2.7rem;
      font-weight: 500;
      letter-spacing: 0;
      list-style: none;
    }

    .package-count {
      display: flex;
      align-items: center;
      margin-bottom: 5.3rem;

      input[type="number"] {
        width: 7.6rem;
        height: 5.6rem;
        border-radius: 0;
        margin-bottom: 0;
        border: 2px solid $blue-itm;
        font-size: $largeFontSize;
      }

      &__label {
        margin-bottom: 0;
        margin-right: 2.3rem;
        font-size: $largeFontSize;
        line-height: 2.7rem;
        font-weight: 600;
        color: $blue-itm;
        letter-spacing: 0;
      }
    }

    .compliance {
      border-top: 1px solid #b5b5b5;
      border-bottom: 1px solid #b5b5b5;
      padding: 5.2rem 0;
      margin-bottom: 5.2rem;

      &__title {
        font-size: $extraLargeFontSize;
        line-height: 3.7rem;
        font-weight: bold;
        color: $blue-itm;
        margin-bottom: 2.8rem;
        margin-left: 1rem;
      }

      &__content {
        display: flex;
        gap: 8rem;
        margin-left: 3.6rem;
      }
    }

    .bypass-quality-switch,
    .non-installation {
      margin-bottom: 5.2rem;

      &__reason {
        margin-top: 2rem;

        label {
          font-size: $extraLargeFontSize;
          font-weight: 600;
          color: $blue-itm;
          margin-bottom: 1rem;
        }

        textarea {
          background-color: white;
          border: 1px solid #707070;
          border-radius: 0;
          padding: 0.5rem 1rem;
          font-size: $extraLargeFontSize;
          height: auto;
          resize: vertical;
        }
      }
    }

    .quality-sheet-form-section {
      padding-bottom: 5.2rem;
      margin-bottom: 5.2rem;
      border-bottom: 1px solid #b5b5b5;

      &__title {
        font-size: $extraLargeFontSize;
        line-height: 3.7rem;
        font-weight: bold;
        margin-bottom: 3.4rem;
        color: $blue-itm;
        letter-spacing: 0;
      }

      &__warning {
        margin-bottom: 2.1rem;
        color: #e64b4b;
        font-size: $extraLargeFontSize;
        line-height: 2.7rem;
        font-weight: 500;
        letter-spacing: 0;
      }

      &__rows {
        display: flex;
        flex-direction: column;
        gap: 5.6rem;
        margin-left: 3.6rem;

        > * {
          display: grid;
          grid-template-columns: 2fr 1fr 1fr;
          font-size: $veryLargeFontSize;
          line-height: 3.2rem;
          color: $blue-itm;
          font-weight: 600;
          letter-spacing: 0;
        }
      }
    }

    .security-check {
      padding-bottom: 5.2rem;
      margin-bottom: 6.3rem;
      border-bottom: 1px solid #b5b5b5;
      &__title {
        font-size: $extraLargeFontSize;
        line-height: 3.7rem;
        font-weight: bold;
        margin-bottom: 5.1rem;
        color: $blue-itm;
        text-transform: uppercase;
        letter-spacing: 0;
      }

      .quality-sheet-form-section {
        border-bottom: none;
        padding-bottom: 0;
      }

      .devices-with-two-inputs,
      .brass-plug-unused-inlet {
        .quality-sheet-form-section {
          &__rows {
            div {
              display: flex;
              flex-direction: column;

              div {
                display: grid;
                grid-template-columns: repeat(3, minmax(0, 1fr));
                row-gap: 2.7rem;
                margin-top: 4.1rem;
              }
            }
          }
        }
      }
    }

    .signature {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 8.8rem;

      &__title {
        font-size: $extraLargeFontSize;
        line-height: 3.7rem;
        font-weight: bold;
        margin-bottom: 2.8rem;
        color: $blue-itm;
      }

      canvas {
        border: 2px solid #707070;
        margin-bottom: 3.9rem;
      }

      &__action {
        background-color: #ffab00;
        color: $blue-itm;
        font-weight: bold;
        font-size: $veryLargeFontSize;
        text-transform: none;
        letter-spacing: 0;
        border: 2px solid #70707088;
        padding: 1.75rem 5rem;
        height: auto;
      }
    }
  }

  .delivery-submit {
    button {
      text-transform: none;
    }
  }
}

@media screen and (min-width: $smallTablet) {
  .quality-sheet {
    margin: 0 2rem;

    &__content {
      padding: 2.5rem;
    }

    &__title {
      font-size: 2.6rem;
      padding: 2.7rem 0;
    }

    &__guidance {
      font-size: $extraLargeFontSize;
    }

    .quality-sheet-form {
      &__error {
        font-size: $extraLargeFontSize;
      }

      .package-count {
        input[type="number"] {
          font-size: $extraLargeFontSize;
        }

        &__label {
          font-size: $extraLargeFontSize;
        }
      }
    }
  }
}

@media screen and (min-width: $tablet) {
  .quality-sheet {
    margin: 0 5rem;

    &__content {
      padding: 5.4rem 8.8rem;
    }

    &__top-container {
      img {
        width: 27.6rem;
        height: 7.3rem;
      }
    }

    .quality-sheet-form {
      .compliance {
        &__title {
          font-size: 2.4rem;
        }

        &__content {
          gap: 11rem;
        }
      }

      .quality-sheet-form-section {
        &__title {
          font-size: 2.6rem;
        }

        &__rows > div {
          font-size: 2.4rem;
        }
      }
    }

    .signature {
      &__title {
        font-size: 2.4rem;
      }

      &__actions {
        padding: 2rem 6.2rem;
      }
    }
  }
}
