.delivery-view {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: $defaultFontSize;

  button {
    padding: 1.4rem 2.1rem;
    margin-bottom: 0;
    font-size: $verySmallFontSize;
    font-family: $font-family;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 12px;
    border: none;
    height: auto;
    line-height: 1;
  }

  .delivery-button-submit {
    width: 100%;
    border-radius: 0;
  }

  select {
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 8" width="30"><path fill="%20274a" d="M0,0l6,8l6-8"/></svg>')
      center right no-repeat;
    font-size: $largeFontSize;
  }

  .banner {
    padding: 2rem 0;
    font-size: $veryLargeFontSize;

    &--warning {
      color: $color-warning;
    }

    &--error {
      color: $color-danger;
    }

    &__error-message {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      span {
        padding: 1rem 0;
        font-style: italic;
      }
    }
  }

  .delivery-submit-button {
    border-radius: 0;
    width: 100%;
    font-size: $largeFontSize;
  }

  &__quality-sheet {
    background-color: #dedede;
    min-height: 100vh;
    height: 100%;
    width: 100%;
    max-width: 112rem;
  }

  &__delivery-sheet {
    padding: 2rem;
    width: 100%;
    max-width: 112rem;
  }
}

.delivery-actions {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.75rem;
  margin-bottom: 4rem;

  &__truck-change {
    button {
      width: 100%;
      background-color: #ff9800;
    }
  }

  &__right-container {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }

  &__client-arrival {
    background-color: #519b5f;
  }

  &__delivery-failed {
    background-color: #cc3a3a;
  }
}

.delivery-informations {
  &__contractor {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4rem;
  }

  &__store,
  &__reference,
  &__delivery-date {
    color: $blue-itm;
    font-size: $extraLargeFontSize;
    line-height: 3.7rem;
    text-transform: uppercase;
    letter-spacing: 0;
  }

  &__delivery-date {
    text-align: center;
    font-size: $veryLargeFontSize;

    &::after {
      content: "";
      display: block;
      width: 32.5rem;
      border-bottom: 2px solid #b5b6b9;
      margin: 3.2rem auto 6.3rem;
    }
  }
}

.client-informations {
  display: grid;
  grid-template-rows: 1fr 1fr;
  align-items: center;
  margin-bottom: 4rem;

  &__name {
    color: $blue-itm;
    font-size: $extraLargeFontSize;
    line-height: 3.7rem;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 0;
    margin-bottom: 1.5rem;
    text-align: center;
  }

  .client-address {
    &__floor,
    &__address,
    &__address-addition,
    &__postal-code-city {
      color: $blue-itm;
      font-size: $veryLargeFontSize;
      line-height: 2.6rem;
      letter-spacing: 0;
      text-align: center;
    }
  }

  .client-contact {
    width: fit-content;
    margin: 0 auto;

    &__email {
      color: $blue-itm;
      font-size: $veryLargeFontSize;
      line-height: 2.6rem;
      letter-spacing: 0;
      text-align: center;
    }

    &__phone {
      display: grid;
      grid-template-columns: auto 1fr;
      align-items: center;

      span {
        margin-right: 2rem;
        color: $blue-itm;
        font-size: $veryLargeFontSize;
        line-height: 2.6rem;
        letter-spacing: 0;

        &:first-of-type {
          margin-bottom: 2rem;
        }
      }

      .phone-link {
        padding: 1rem 2rem;
        background-color: $blue-itm;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 1.2rem;
        color: white;
        font-size: $veryLargeFontSize;
        font-weight: bold;
        letter-spacing: 0;

        &:first-of-type {
          margin-bottom: 2rem;
        }
      }
    }
  }
}

.disclaimer-accordion {
  margin-bottom: 4rem;

  .disclaimer-accordion-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.6rem 2rem;
    border-top: 2px solid $blue-itm;
    border-bottom: 2px solid $blue-itm;

    &__title {
      color: $blue-itm;
      font-size: $extraLargeFontSize;
      font-weight: bold;
      letter-spacing: 0;
      user-select: none;
    }

    &__action {
      color: $blue-itm;
      user-select: none;
    }
  }

  .disclaimer-accordion-content {
    padding: 3rem;
    background-color: #f7f7f7;
    color: $blue-itm;
    line-height: 1;
    user-select: none;

    span,
    b {
      line-height: 2.9rem;
    }

    &__first-line {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;

      span {
        margin-right: 1.5rem;
        white-space: nowrap;
      }

      input {
        margin: 0;
        max-width: 100%;
        background-color: white;
        border: 1px solid #707070;
        border-radius: 0;
        padding: 0.5rem 1rem;
      }
    }

    textarea {
      background-color: white;
      border: 1px solid #707070;
      border-radius: 0;
      padding: 0.5rem 1rem;
      margin: 1.6rem 0;
      height: auto;
      resize: vertical;
    }

    &__last-line {
      margin-top: 1.3rem;
      text-decoration: underline;
    }
  }
}

.delivery-form {
  margin-bottom: 0;

  &__error {
    text-align: center;
    font-style: italic;
    font-size: $extraLargeFontSize;
    line-height: 3.2rem;
    color: #cc3a3a;
    letter-spacing: 0;
    margin-bottom: 1rem;

    .fa-exclamation-triangle {
      font-size: 3rem;
      margin-right: 1rem;
    }
  }

  .funding-warning {
    text-align: center;
    font-size: $extraLargeFontSize;
    font-weight: bold;
    color: $color-warning;
    margin-bottom: 3rem;
  }

  .payment-comment {
    display: flex;
    flex-direction: column;
    margin-bottom: 4rem;

    &__label {
      color: $blue-itm;
      font-size: $largeFontSize;
      font-weight: bold;
      text-transform: uppercase;
      white-space: nowrap;
      margin-right: 2rem;
      line-height: 1;
      letter-spacing: 0;
    }

    &__textarea {
      border: 1px solid #707070;
      border-radius: 0;
      height: auto;
      resize: vertical;
      font-size: $largeFontSize;
      line-height: 2.9rem;
      margin-bottom: 0;
    }
  }

  .delivery-payments {
    display: grid;
    grid-template-rows: repeat(2, minmax(0, 1fr));
    grid-template-columns: inherit;
    grid-gap: 2rem;
    margin-bottom: 6rem;

    .delivery-payment {
      display: flex;
      flex-direction: column;
      align-items: center;

      &__title {
        font-size: $largeFontSize;
        color: $blue-itm;
        text-transform: uppercase;
        text-align: center;
        letter-spacing: 0;
        margin-bottom: 2rem;
        font-weight: bold;
      }

      &__payments {
        display: flex;
        flex-direction: column;
        align-items: center;

        .payment-list {
          .payment-item {
            display: grid;
            grid-template-columns: 50fr 30fr 20fr;
            align-items: flex-end;

            input,
            select,
            button {
              margin-bottom: 0;
            }

            input,
            select {
              border: 1px solid #707070;
              border-radius: 0;
            }

            &__method,
            &__amount {
              margin-right: 1rem;
              label {
                font-size: $defaultFontSize;
                color: $blue-itm;
              }
            }

            &__action {
              .button {
                background-color: #cc3a3a;
                border: none;
                font-size: $smallFontSize;

                i {
                  font-size: $smallFontSize;
                }
              }
            }
          }
        }

        .add-payment-button {
          padding: 0.8rem 2rem;
          font-size: $defaultFontSize;
          line-height: 2.4rem;
          font-weight: bold;
          box-shadow: 0px 3px 6px #00000029;
          border-radius: 1.2rem;
          text-transform: none;
        }
      }
    }
  }

  .sav-section {
    &__bypass-reason {
      color: $blue-itm;

      .rc-checkbox {
        margin-right: 1rem;
      }
    }

    .sav-section-comment {
      display: flex;
      flex-direction: column;
      margin-bottom: 3rem;

      &__label {
        color: $blue-itm;
        font-size: $largeFontSize;
        font-weight: bold;
        text-transform: uppercase;
        white-space: nowrap;
        margin-right: 2rem;
        line-height: 1;
        letter-spacing: 0;
      }

      &__textarea {
        border: 1px solid #707070;
        border-radius: 0;
        height: auto;
        resize: vertical;
        min-height: 10.7rem;
        margin-bottom: 0;
        font-size: $largeFontSize;
        font-style: italic;
      }
    }
  }

  &__observations {
    display: flex;
    color: $blue-itm;
    font-size: $largeFontSize;
    line-height: 2.9rem;
    margin-bottom: 3rem;
    letter-spacing: 0;

    span:first-child {
      text-transform: uppercase;
      margin-right: 1.5rem;
      font-weight: bold;
      white-space: nowrap;
    }

    span:last-child {
      font-style: italic;
    }
  }

  .delivery-form-articles {
    &__title-container {
      display: block;
    }

    &__title {
      color: $blue-itm;
      font-size: $largeFontSize;
      font-weight: bold;
      line-height: 3.7rem;
      text-transform: uppercase;
      letter-spacing: 0;
      margin-right: 1rem;
    }

    &__warning {
      font-style: italic;
      font-size: $defaultFontSize;
      line-height: 3.2rem;
      color: #cc3a3a;
      letter-spacing: 0;

      .fa-exclamation-triangle {
        font-size: 2.5rem;
        margin-right: 0.5rem;
      }
    }

    &__separator {
      width: 100%;
      border-bottom: 2px solid $blue-itm;
      margin-top: 2.4rem;
    }

    .article-list {
      display: flex;
      flex-direction: column;
      margin-bottom: 4rem;
    }
  }

  .photo-input {
    margin-bottom: 6rem;
    margin-left: 0;

    &__label {
      color: $blue-itm;
      font-size: $largeFontSize;
      font-weight: bold;
      text-transform: uppercase;
    }

    .addPhotoButton,
    .photos-list {
      margin-bottom: 0;
    }
  }
}

@media screen and (min-width: $smallTablet) {
  .delivery-view {
    &__delivery-sheet {
      padding: 3rem;
    }

    .client-informations {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: inherit;

      &__name {
        text-align: left;
      }

      .client-address {
        &__floor,
        &__address,
        &__address-addition,
        &__postal-code-city {
          text-align: left;
        }
      }

      .client-contact {
        &__email {
          text-align: left;
        }
      }
    }
  }

  .delivery-actions {
    flex-direction: row;

    &__truck-change {
      button {
        width: auto;
      }
    }

    &__right-container {
      flex-direction: row;
    }
  }

  .delivery-form {
    .delivery-payments {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-template-rows: inherit;
      grid-gap: 0;
    }
  }
}

@media screen and (min-width: $tablet) {
  .delivery-view {
    &__delivery-sheet {
      padding: 3rem 4rem;
    }
  }

  .delivery-form {
    .payment-comment {
      flex-direction: row;
    }

    .delivery-form-articles {
      &__title-container {
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
    }

    .sav-section {
      .sav-section-comment {
        flex-direction: row;
      }
    }
  }
}
