.app-login {
  margin-top: 8rem;
  & > .login-button {
    margin-top: 1.5rem;
  }

  & .title {
    text-align: center;
  }
  & .logo {
    font-weight: bold;
    height: 12rem;
    line-height: 12rem;
    margin: 0 auto 80px auto;
  }
}
