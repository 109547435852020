.button {
  color: $color-primary;
  border-color: $color-primary;

  &.-warning {
    background-color: $color-warning;
    border-color: $color-warning;
  }
  &.-danger {
    background-color: #f03434;
    border-color: #f03434;
  }
  &.-positive {
    background-color: #17af62;
    border-color: #17af62;
  }
  &.-small {
    font-size: 0.8rem;
    height: 2.8rem;
    line-height: 2.8rem;
    padding: 0 1.5rem;
  }
  &.-large {
    font-size: 1.4rem;
    height: 4.5rem;
    line-height: 4.5rem;
    padding: 0 2rem;
  }
}
