.modal-calendar {
  z-index: 200;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.45);

  &.open {
    visibility: visible;
  }

  &__calendar {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  &__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
  }

  //Customize calendar module style (bad BEM)
  .react-calendar {
    width: 100%;
    height: auto;
    padding-bottom: 1rem;

    button:not(.react-calendar__month-view__days__day--weekend, .react-calendar__month-view__days__day--neighboringMonth) {
      color: black;
    }

    &__navigation {
      display: flex;
      align-items: center;
      margin-bottom: 0;
      height: 6rem;
      background: #f0f0f0;

      &__label {
        font-size: 2rem;
      }
      &__arrow {
        font-size: 2.6rem;
      }
    }

    &__month-view__weekdays__weekday {
      font-size: 1.8rem;
    }

    &__tile--active {
      background-color: $color-primary;

      abbr {
        color: white;
      }
    }

    &__month-view__days {
      button {
        font-size: 2rem;
        height: 5rem;
      }
    }
  }
}
