/*
*
* Fonts *
*
*/

/* roboto-regular - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Regular"),
    url("./fonts/roboto-v18-latin-regular.woff2") format("woff2"),
    url("./fonts/roboto-v18-latin-regular.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-700 - latin */

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: local("Roboto Bold"), local("Roboto-Bold"),
    url("./fonts/roboto-v18-latin-700.woff2") format("woff2"),
    url("./fonts/roboto-v18-latin-700.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* montserrat-300 - latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  src: url("./fonts/Montserrat/montserrat-v24-latin-300.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("./fonts/Montserrat/montserrat-v24-latin-300.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/Montserrat/montserrat-v24-latin-300.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("./fonts/Montserrat/montserrat-v24-latin-300.woff") format("woff"),
    /* Modern Browsers */ url("./fonts/Montserrat/montserrat-v24-latin-300.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./fonts/Montserrat/montserrat-v24-latin-300.svg#Montserrat")
      format("svg"); /* Legacy iOS */
}
/* montserrat-regular - latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/Montserrat/montserrat-v24-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("./fonts/Montserrat/montserrat-v24-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/Montserrat/montserrat-v24-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("./fonts/Montserrat/montserrat-v24-latin-regular.woff") format("woff"),
    /* Modern Browsers */
      url("./fonts/Montserrat/montserrat-v24-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./fonts/Montserrat/montserrat-v24-latin-regular.svg#Montserrat")
      format("svg"); /* Legacy iOS */
}
/* montserrat-600 - latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  src: url("./fonts/Montserrat/montserrat-v24-latin-600.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("./fonts/Montserrat/montserrat-v24-latin-600.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/Montserrat/montserrat-v24-latin-600.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("./fonts/Montserrat/montserrat-v24-latin-600.woff") format("woff"),
    /* Modern Browsers */ url("./fonts/Montserrat/montserrat-v24-latin-600.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./fonts/Montserrat/montserrat-v24-latin-600.svg#Montserrat")
      format("svg"); /* Legacy iOS */
}
/* montserrat-800 - latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  src: url("./fonts/Montserrat/montserrat-v24-latin-800.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("./fonts/Montserrat/montserrat-v24-latin-800.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/Montserrat/montserrat-v24-latin-800.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("./fonts/Montserrat/montserrat-v24-latin-800.woff") format("woff"),
    /* Modern Browsers */ url("./fonts/Montserrat/montserrat-v24-latin-800.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./fonts/Montserrat/montserrat-v24-latin-800.svg#Montserrat")
      format("svg"); /* Legacy iOS */
}

@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/solid";
