@mixin delivery-article-switch($sizeRatio) {
  .delivery-article-switch {
    position: relative;
    display: inline-block;
    width: 7.8rem * $sizeRatio;
    height: 5rem * $sizeRatio;
    margin-right: 2rem;
    margin-bottom: 0;
    flex: 0.3;
    max-width: 7.8rem;

    input {
      opacity: 0;
      width: 0;
      height: 0;

      &:checked + .slider {
        background-color: #519b5f;
      }

      &:focus + .slider {
        box-shadow: 0 0 1px #519b5f;
      }

      &:checked + .slider:before {
        transform: translateX(4.6rem - $sizeRatio);
      }
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #cc3a3a;
      transition: 0.4s;
      border-radius: 34px * $sizeRatio;

      &::before {
        position: absolute;
        content: "";
        height: 3.6rem * $sizeRatio;
        width: 3.6rem * $sizeRatio;
        left: 7px * $sizeRatio;
        bottom: 7px * $sizeRatio;
        background-color: white;
        transition: 0.4s;
        border-radius: 50%;
      }

      i {
        position: absolute;
        font-size: 1.6rem * $sizeRatio;
        color: white;

        &:first-of-type {
          left: 1rem * $sizeRatio;
          top: 1.5rem * $sizeRatio;
        }

        &:last-of-type {
          right: 1.2rem * $sizeRatio;
          top: 1.5rem * $sizeRatio;
          transform: rotate(90deg);
        }
      }
    }

    &--disabled {
      input {
        + .slider {
          background-color: $grey-medium;
        }
        &:checked + .slider {
          background-color: $grey-medium;
        }
      }
    }
  }
}

.delivery-article {
  &__informations {
    display: flex;
    flex-direction: column;
  }

  &__name,
  &__options {
    color: $blue-itm;
    font-size: $veryLargeFontSize;
    line-height: 3.2rem;
    letter-spacing: 0;
  }

  &__name {
    font-style: italic;
    margin-bottom: 1.5rem;
  }

  &__options {
    font-weight: bold;
    letter-spacing: 0;
  }

  @include delivery-article-switch(0.8);

  .delivery-article-accordion {
    width: 100%;

    .delivery-article-accordion-header {
      width: 100%;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #b5b6b9;

      &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 2rem 0;
      }

      &__action {
        display: flex;
        padding-left: 0.5rem;
      }

      &__left-container,
      &__right-container {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      &__left-container {
        width: 100%;

        .delivery-article__informations {
          flex: 0.7;
        }

        .delivery-article-switch {
          flex: 0.3;
        }
      }

      &__right-container {
        width: 100%;
        gap: 0.5rem;

        .fas {
          color: $blue-itm;
          user-select: none;
        }
      }
    }
  }

  .delivery-order-article {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    background-color: #f7f7f7;
    border-bottom: 1px solid #b5b6b9;
    padding: 0.5rem 0;

    &__left-container {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: space-around;
      width: 100%;
      padding: 0.5rem 0;

      @include delivery-article-switch(0.7);
    }

    &__index,
    &__name {
      color: $blue-itm;
      line-height: 3.2rem;
      letter-spacing: 0;
    }

    &__index {
      flex: 0.1;
      font-size: $veryLargeFontSize;
      font-weight: bold;
      margin-right: 0.5rem;
    }

    &__name {
      flex: 0.6;
      font-size: $largeFontSize;
      font-style: italic;
      margin-right: 0;
    }
  }

  .failure-reason-selector {
    flex: 1;
    padding-bottom: 1rem;
    margin-bottom: 0;
    margin-right: 0.5rem;
    border: 1px solid $blue-itm;
    border-radius: 0;
    background-color: white;
  }
}

@media screen and (min-width: $smallTablet) {
  .delivery-article {
    .delivery-order-article {
      flex-direction: row;
      padding: 2rem;

      &__left-container {
        flex: 2;
        padding: 0;
      }

      &__index {
        flex: 0.1;
        font-size: $veryLargeFontSize;
      }

      &__name {
        flex: 0.7;
        font-size: $largeFontSize;
        margin-right: 0.5rem;
      }
    }

    .failure-reason-selector {
      padding-bottom: 0;
    }

    .delivery-article-switch {
      flex: 0.2;
    }
  }
}

@media screen and (min-width: $tablet) {
  .delivery-article {
    .delivery-article-accordion {
      .delivery-article-accordion-header {
        &__content {
          flex-direction: row;
        }

        &__left-container,
        &__right-container {
          display: flex;
          flex-direction: center;
        }
      }
    }
  }
}
