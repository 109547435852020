.alert {
  &--info {
    text-align: center;
    padding: 2rem;
    color: rgb(102, 60, 0);
    background-color: rgb(255, 244, 229);
  }

  &--error {
    text-align: center;
    padding: 2rem;
    color: rgb(95, 33, 32);
    background-color: rgb(253, 237, 237);
  }
}
