/* FONTS */
$font-family: Roboto, sans-serif;
$verySmallFontSize: 1.4rem;
$smallFontSize: 1.5rem;
$defaultFontSize: 1.6rem;
$largeFontSize: 1.8rem;
$veryLargeFontSize: 2rem;
$extraLargeFontSize: 2.2rem;

/* COLORS */
$blue-itm: #20274a;
$yellow-itm: #f9c900;
$orange: #ff9800;
$red: #f44336;
$softRed: #973c3c;
$red-light: #f47665;
$green: #11bb41;
$green-light: #15e04e;
$grey-light: #f8f8f8;
$grey-medium: #cdcdcd;
$grey-dark: #606c76;

$color-primary: $blue-itm;
$color-secondary: $grey-dark;
$color-tertiary: $yellow-itm;
$color-danger: $red;
$color-danger-light: $red-light;
$color-warning: $orange;

/* BORDERS */
$border-grey-light: 1px solid rgb(225, 225, 225);

/* BREAKPOINTS */
$smallTablet: 600px;
$tablet: 768px;
