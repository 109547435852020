.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  background-color: #fff;

  & > .header {
    width: 100%;
    padding: 0.5rem;
    margin-bottom: 1rem;
    background-color: #eeeeee;
  }

  & > .contain {
    padding: 1rem;
  }

  & > .footer-section {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 1rem 0;
    text-align: right;
    background-color: #eeeeee;
    .button {
      margin: auto 0.5rem;
    }
  }
}
