.tooltip {
  z-index: 100;

  > :first-child {
    cursor: pointer;
  }

  &__text {
    position: absolute;
    top: 100%;
    left: 0;
    user-select: none;
    border: 2px solid $color-secondary;
    border-radius: 0.25rem;
    background-color: white;
    padding: 0.25rem 0.5rem;
    max-width: 20rem;
  }
}
