.offline-recap {
  background-color: $color-warning;
  color: white;
  margin: 0 -2rem 2rem;
  padding: 1rem 2rem;

  &__title {
    font-weight: bold;
  }

  &__body {
    font-size: 1.5rem;
    line-height: 1.3;
  }

  ul {
    margin: 0;
  }

  li {
    margin: 0;
  }
}
