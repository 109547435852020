.returned-articles {
  .contain {
    padding: 3rem auto;
  }

  .header {
    padding: 1rem;
    text-align: center;
  }

  &__list {
    overflow: auto;
    max-height: calc(100vh - 90px - 60px); // - HEADER - FOOTER
  }

  &__infos {
    color: rgb(102, 60, 0);
    background-color: rgb(255, 244, 229);
    text-align: center;
    padding: 2rem;
  }
}

.returned-article {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  & > div {
    margin: 0 1rem;
  }

  &__article-check {
    span {
      margin-left: 0.5rem;
    }
  }
}
