.app-container {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  & > .mainview {
    flex: 1;
  }
}

@import "~react-circular-progressbar/dist/styles.css";

@import "./styles/styles";
@import "./components/components";
@import "./pages/pages";

@import "~milligram/src/milligram";
