.write-comment-modal {
  z-index: 200;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.5);

  &--visible {
    visibility: visible;
  }

  &__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 60rem;
    height: auto;
  }

  &__input {
    width: 100%;
    min-height: 30rem;
    padding: 1rem;
    font-size: 1.8rem;
    background-color: white;
  }

  &__title {
    width: 100%;
    background-color: $color-primary;
    color: white;
    text-align: center;
    font-size: 2.6rem;
    font-weight: bold;
  }

  &__buttons {
    button {
      font-size: 1.6rem;
      width: 25rem;

      &:first-child {
        background-color: white;
        color: black;
        border: none;
        margin-right: 4rem;
      }
    }
  }
}
