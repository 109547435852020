.app-header {
  width: 100%;
  height: 50px;
  margin-bottom: 1rem;
  background: $color-primary;
  position: fixed;
  z-index: 110;
  box-shadow: 0px 1px 20px 1px rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;

  & + * {
    margin-top: 50px;
  }

  .left {
    height: 100%;
    display: flex;
    align-items: center;
    position: absolute;
    left: 10px;
    top: 0;
  }

  .right {
    height: 100%;
    display: flex;
    align-items: center;
    position: absolute;
    right: 10px;
    top: 0;
  }

  .title {
    flex: 1;
    text-align: center;
    color: #ffffff;
    margin: 0;
    font-size: 2.4rem;
  }
}

.header-button {
  & > .icon-button {
    margin: 0;
    font-size: 2rem;
    line-height: 1.3;
    background-color: transparent;
    border-color: transparent;
    padding: 0;
  }
}

.icon-button {
  & > .label {
    display: inline;
    font-size: 0.6em;
    vertical-align: middle;
    padding-left: 0.6em;
    text-transform: none;
  }
}

@media screen and (min-width: 768px) {
  .app-header {
    .title {
      font-size: 3rem;
    }
  }
}

@media screen and (min-width: 1024px) {
  .app-header {
    .title {
      font-size: 3.6rem;
    }
  }
}
