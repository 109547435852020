.barcode-scanner {
  display: none;

  &--visible {
    display: block;
  }
  .scan-overlay {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    position: fixed;
    background: black;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10000;

    &__close {
      position: absolute;
      top: 1rem;
      right: 1rem;
    }

    &__video-container {
      width: 30rem;
      height: auto;

      video {
        width: 100%;
      }
    }

    &__close-button {
      width: 3rem;
      height: 3rem;
      font-size: 2.5rem;
      line-height: normal;
      color: white;
      padding: 1rem;
      background-color: transparent;
      border: none;
      padding: 0;
    }

    &__retry-button {
      background-color: $color-tertiary;
      color: black;
    }

    &__target {
      margin-top: 4rem;
    }

    &__target,
    &__status {
      font-size: 1.8rem;
      color: white;
    }

    &__actions {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      button {
        background-color: $color-tertiary;
        color: black;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .barcode-scanner {
    .scan-overlay {
      &__video-container {
        width: 60rem;
      }

      &__actions {
        flex-direction: row;
      }
    }
  }
}
