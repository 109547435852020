.checkbox {
  display: flex;
  align-items: center;

  .rc-checkbox {
    display: flex;

    &-inner {
      width: 3.7rem;
      height: 3.7rem;
      border-radius: 0;
      border: 1px solid $blue-itm;
    }
  }

  .rc-checkbox-inner:after {
    left: 12px;
    top: 1px;
    width: 11px;
    height: 24px;
    border: 3px solid #ffffff;
    border-top: 0;
    border-left: 0;
  }

  .rc-checkbox-checked .rc-checkbox-inner:after {
    left: 12px;
    top: 1px;
    width: 11px;
    height: 24px;
    border: 3px solid #ffffff;
    border-top: 0;
    border-left: 0;
  }

  &__label {
    font-size: $veryLargeFontSize;
    line-height: 3.2rem;
    color: $blue-itm;
    font-weight: 600;
    margin-left: 2rem;
    letter-spacing: 0;
  }
}

@media screen and (min-width: $tablet) {
  .checkbox {
    &__label {
      font-size: $extraLargeFontSize;
    }
  }
}
