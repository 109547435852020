.accordion {
  width: 100%;
  position: relative;
  color: black;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem 2rem;
    margin: 1rem 0;
    background-color: $color-tertiary;
    user-select: none;
    cursor: pointer;
  }

  &__content {
    display: none;
    height: 100%;
    padding: 1rem 0;

    &--visible {
      display: flex;
      flex-direction: column;
    }
  }
}
