.halfday-switch {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;

  .halfday-switch__item {
    background-color: #e4e4e4;
    color: black;
    font-size: 1.5rem;
    line-height: 1;
    text-align: center;
    padding: 1.5rem 0;
    width: 12rem;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3),
      0 1px rgba(255, 255, 255, 0.1);
    cursor: pointer;
    transition: all 0.1s ease-in-out;

    &:first-of-type {
      border-radius: 4px 0 0 4px;
    }

    &:last-of-type {
      border-radius: 0 4px 4px 0;
    }

    &.selected {
      background-color: $color-primary;
      color: white;
      box-shadow: none;
    }
  }
}
