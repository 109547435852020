$transition-duration: 0.1s;

/* Default state */
.rc-checkbox {
  white-space: nowrap;
  cursor: pointer;
  outline: none;
  display: inline-block;
  position: relative;
  line-height: 1;
  vertical-align: middle;
}
.rc-checkbox:hover .rc-checkbox-inner,
.rc-checkbox-input:focus + .rc-checkbox-inner {
  border-color: $color-primary;
}
.rc-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: inline-block;
  width: 28px;
  height: 28px;
  border-width: 1px;
  border-style: solid;
  border-radius: 3px;
  border-color: #d9d9d9;
  background-color: #ffffff;
  transition: border-color $transition-duration
      cubic-bezier(0.68, -0.55, 0.27, 1.55),
    background-color $transition-duration cubic-bezier(0.68, -0.55, 0.27, 1.55);
}
.rc-checkbox-inner:after {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  left: 12px;
  top: 1px;
  display: table;
  width: 10px;
  height: 16px;
  border: 2px solid #ffffff;
  border-top: 0;
  border-left: 0;
  content: " ";
  animation-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
  animation-duration: $transition-duration;
  animation-name: amCheckboxOut;
}
.rc-checkbox-input {
  position: absolute;
  left: 0;
  z-index: 9999;
  cursor: pointer;
  opacity: 0;
  top: 0;
  bottom: 0;
  right: 0;
}
/* Checked state */
.rc-checkbox-checked:hover .rc-checkbox-inner {
  border-color: $color-primary;
}
.rc-checkbox-checked .rc-checkbox-inner {
  border-color: $color-primary;
  background-color: $color-primary;
}
.rc-checkbox-checked .rc-checkbox-inner:after {
  transform: rotate(45deg);
  position: absolute;
  left: 8px;
  top: 2px;
  display: table;
  width: 10px;
  height: 16px;
  border: 2px solid #ffffff;
  border-top: 0;
  border-left: 0;
  content: " ";
  animation-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
  animation-duration: $transition-duration;
  animation-name: amCheckboxOut;
}
@media print {
  .rc-checkbox-checked .rc-checkbox-inner {
    box-shadow: inset 0 0 0 16px $color-primary;
  }
}
.rc-checkbox-disabled.rc-checkbox-checked:hover .rc-checkbox-inner {
  border-color: #d9d9d9;
}
.rc-checkbox-disabled.rc-checkbox-checked .rc-checkbox-inner {
  background-color: #f3f3f3;
  border-color: #d9d9d9;
}
.rc-checkbox-disabled.rc-checkbox-checked .rc-checkbox-inner:after {
  animation-name: none;
  border-color: #cccccc;
}
@media print {
  .rc-checkbox-disabled.rc-checkbox-checked .rc-checkbox-inner {
    box-shadow: inset 0 0 0 16px #f3f3f3;
  }
}
.rc-checkbox-disabled:hover .rc-checkbox-inner {
  border-color: #d9d9d9;
}
.rc-checkbox-disabled .rc-checkbox-inner {
  border-color: #d9d9d9;
  background-color: #f3f3f3;
}
.rc-checkbox-disabled .rc-checkbox-inner:after {
  animation-name: none;
  border-color: #f3f3f3;
}
.rc-checkbox-disabled .rc-checkbox-inner-input {
  cursor: default;
}
@keyframes amCheckboxIn {
  0% {
    opacity: 0;
    transform-origin: 50% 50%;
    transform: scale(0, 0) rotate(45deg);
  }
  100% {
    opacity: 1;
    transform-origin: 50% 50%;
    transform: scale(1, 1) rotate(45deg);
  }
}
@keyframes amCheckboxOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
