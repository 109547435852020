.round-header {
  margin: 2rem 0;

  & .title {
    font-size: 2rem;
    text-align: center;
  }
  & .label {
    font-weight: bold;
  }
  & .info {
    text-align: right;
  }
  & .round-filter {
    margin-top: 2rem;
  }
  .action {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;

    .button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 3rem;
      height: 3rem;
      padding: 0.5rem;
    }
  }
}

.round-filter {
  text-align: center;
  & > .button {
    margin: auto 1rem;
  }

  & > .button > .radio {
    display: none !important;
  }
}

.round-section {
  & > .actions {
    text-align: center;
    padding-bottom: 15px;
    & > p.error {
      color: #d22;
    }
  }
  & > .title {
    text-align: center;
    text-transform: uppercase;
    font-size: 2.5rem;
    margin: 3rem 0;
  }

  & > .list {
    list-style: none;

    &:last-child {
      border-bottom: 1px solid $color-primary;
    }
  }
}

.round-footer {
  text-align: right;

  > .button {
    margin: 0 0.5rem;
  }
}
