.sav-section {
  .photo-snapper {
    position: fixed;
    background: black;
    z-index: 10000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .loading {
      width: 100%;
      font-size: 50px;
      line-height: 50%;
      text-align: center;
    }

    .cameraDevice {
      color: white;
      margin: 0 auto;
      width: 90%;
    }

    .cameraView {
      max-width: 90%;
      max-height: 50%;
      margin: 5%;
    }

    .takePictureButton {
      width: 95%;
      margin: 0 2.5%;
    }

    .cancelButton {
      background: #ff0000;
      width: 95%;
      margin: 2.5%;
    }
  }

  .photos-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-content: flex-start;

    list-style-type: none;
    .photo-item {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;

      &.-remove {
        .photo-holder {
          background-color: #9e9e9e;
          opacity: 0.25;
        }
      }
      &.-removed {
        .photo-holder {
          background-color: #eee;
          border-color: #ccc;
          opacity: 0.25;
          width: 300px;
          height: 210px;
          line-height: 210px;
          text-align: center;
          color: #333;
          font-weight: bold;
        }
      }

      .photo {
        max-width: 300px;
        max-height: 210px;
        box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.75);
      }

      .delete,
      .undelete {
        width: 100%;
        margin: 0;
        margin-top: 10px;
      }

      .delete {
        background-color: $color-danger;
        border-color: $color-danger;
      }

      .undelete {
        background-color: #607d8b;
        border-color: #607d8b;
      }

      .addPhotoButton {
        text-align: center;
        font-size: 20px;
        padding-bottom: 68.5%;
        h2 {
          font-size: 100px;
          margin-bottom: 0;
        }
      }

      .time {
        display: block;
        width: 100%;
        margin: 10px 0 0;
      }
    }
  }
}
