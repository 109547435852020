.pickup-item {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 1rem 1.2rem 0 1.1rem;
  border-top: 1px solid $color-primary;
  background-color: $color-tertiary;

  $root: &;

  &:hover,
  &:active,
  &:focus {
    #{$root} {
      &__store,
      &__reference {
        color: #1c7fd1;
      }
    }

    .fa-chevron-right,
    .fa-dolly {
      color: #1c7fd1;
    }
  }

  &__top-container {
    display: flex;
    gap: 2rem;
    margin-bottom: 2rem;
  }

  &__store,
  &__reference {
    flex: 1;
  }

  &__store {
    color: $color-primary;
  }

  &__reference {
    color: $color-primary;
  }

  .reorder {
    position: absolute;
    right: -0.25rem;
    padding: 0.5rem;
    margin: 0;
    height: auto;
    line-height: initial;

    &.-up {
      top: 0.5em;
    }
    &.-down {
      bottom: -0.25em;
    }
  }

  .fa-chevron-right {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translate(0, -50%);
    opacity: 0.5;
    color: $color-primary;

    &.hidden {
      opacity: 0;
    }
  }

  .fa-dolly {
    color: $color-primary;
    padding: 1rem 0;
  }
}
