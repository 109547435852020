.button-with-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: fit-content;

  i {
    font-size: 1.8rem;
  }
}
