/*
//
// GENERAL STYLES
//
*/

html {
  font-size: 62.52% !important;

  body {
    margin: 0;
    padding: 0;
    font-family: $font-family;
    line-height: 1;
  }
}

input,
textarea {
  font-family: $font-family;
  font-size: 1.6rem;
}
