.modal-carousel {
  z-index: 200;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.45);

  &--visible {
    visibility: visible;
  }

  &__wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 65rem;
    height: 65rem;
  }

  &__photos {
    display: flex;
    align-items: center;
    padding-bottom: 1rem;
    width: 65rem;
    height: auto;
    position: relative;
  }

  &__photo {
    display: none;
    width: 100%;
    height: 100%;
    margin: 0 1rem;
    position: relative;

    &--visible {
      display: block;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__delete {
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      background-color: $red;
      border-color: $red;
      padding: 2.5rem 4rem;
      font-size: 1.6rem;
    }
  }

  .fas {
    color: white;
  }
}

.delete-alert {
  z-index: 210;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  gap: 1rem;
  background-color: rgba(0, 0, 0, 0.5);

  &--visible {
    visibility: visible;
  }

  button {
    background-color: $color-tertiary;
    border: none;
    color: $color-primary;
  }
}
