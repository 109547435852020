.order-item {
  display: flex;
  justify-content: center;
  padding: 0 1rem;

  &__title {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    color: $color-primary;
    font-size: 1.8rem;

    .fas {
      padding-left: 2rem;
    }
  }

  &__actions {
    display: flex;
    justify-content: center;
    gap: 1rem;

    button {
      border: none;
    }

    .write-comment-button {
      background-color: $color-tertiary;
      color: $color-primary;

      &:hover,
      &:focus {
        background-color: lighten($color-tertiary, 20%);
      }
    }
  }

  .accordion {
    max-width: 112rem;

    &__header {
      background-color: white;
      border-top: 1px solid black;
      padding: 2rem 1rem;
      margin: 0;

      &.completed {
        background-color: $green;
      }

      &.partially {
        background-color: $orange;
      }

      &.failed {
        background-color: $red;
      }
    }

    &__content {
      padding: 0;
    }
  }
}
