/**
 * Titles
 */

.h1,
h1 {
  font-size: 3.8rem;
  line-height: 1.2;
}

.h2,
h2 {
  font-size: 2.8rem;
  line-height: 1.25;
}

.h3,
h3 {
  font-size: 2rem;
  line-height: 1.3;
}

.h4,
h4 {
  font-size: 1.4rem;
  letter-spacing: -0.08rem;
  line-height: 1.35;
}

.h5,
h5 {
  font-size: 1rem;
  letter-spacing: -0.05rem;
  line-height: 1.5;
}

.h6,
h6 {
  font-size: 0.8rem;
  letter-spacing: 0;
  line-height: 1.4;
}
