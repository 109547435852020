._text-center {
  text-align: center !important;
}

._text-right {
  text-align: right !important;
}

._text-left {
  text-align: left !important;
}

._pull-right {
  float: right !important;
}

._pull-left {
  float: left !important;
}

._border-error,
._border-error,
._border-error:focus {
  border-color: rgb(230, 52, 52) !important;
}

._text-error {
  color: rgb(230, 52, 52) !important;
}
._text-warning {
  color: $color-warning !important;
}

._vertical-spacing {
  padding: 2rem 0;
}

._none {
  display: none !important;
}
